<template>
  <div style="min-width: 1200px" ref="container" class="scroll-container">
    <div class="topBg">
      <img src="@/assets/img/header-bg.png" alt="" />
      <div class="blnking-text">
        <img src="@/assets/img/title_text_img.png" alt="" class="blnking-text-img"/>   
      </div>
    </div>
    <div class="menu-tab" :class="isFixed ? 'menu-fixed' : ''" v-if="isFixed">
      <ul class="flex-b content-max-w">
        <li v-for="(item, index) in data.menuList" :key="index" :class="currentMenuIndex == index ? 'point check-menu' : 'point'" @click="scrollToSection(index, item.id)">
          {{ item.title }}
          <p class="check-line" v-if="currentMenuIndex == index"></p>
        </li>
      </ul>
    </div>
    <div class="menu-tab" style="top: 60px " :class="isFixed ? 'menu-fixed' : ''" v-if="isFixed">
      <ul class="flex-b content-max-w">
        <li v-for="(item, index) in data.twoMenuList" :key="index" :class="currentMenuIndex-6 == index ? 'point check-menu' : 'point'" @click="scrollToSection(index+7, item.id)">
          {{ item.title }}
          <p class="check-line" v-if="currentMenuIndex-6 == index"></p>
        </li>
      </ul>
    </div>
    <!-- tab菜单 -->
    <div class="menu-tab">
      <ul class="flex-b content-max-w" >
        <li v-for="(item, index) in data.menuList" :key="index" :class="currentMenuIndex == index ? 'point check-menu' : 'point'" @click="scrollToSection(index, item.id)">
          {{ item.title }}
          <p class="check-line" v-if="currentMenuIndex == index"></p>
        </li>
      </ul>
    </div>
    <div class="menu-tab">
        <ul class="flex-b content-max-w" style="border-top: 1px solid #D9D9D9;">
            <li v-for="(item, index) in data.twoMenuList" :key="index" :class="currentMenuIndex-7 == index ? 'point check-menu' : 'point'" @click="scrollToSection(index+7, item.id)">
            {{ item.title }}
            <p class="check-line" v-if="currentMenuIndex-7 == index"></p>
            </li>
        </ul>
    </div>
    <!-- 简介 -->
    <div v-if="tabValue==0" class="introduct-module module-main" id="module1" ref="contentSectionRefs1">
      <div class="content-max-w introduction">
        <div class="introduction-content">
            <div style="font-size: 24px;color: #920407;font-weight:bold">聚世界华商，创中华伟业。让历史铭记，让后人传承。</div>
            <div class="flex-c title">
                <img src="@/assets/img/introduct-l.png" alt="" class="line"/>
                <div class="title-text">简介</div>
                <img src="@/assets/img/introduct-r.png" alt="" class="line"/>
            </div>
          <div class="english-name" style="margin-bottom: 16px">
            <!-- INTRODUCTION -->
          </div>
          <p>
            《世界华商经济年鉴》是由中国社会科学院主管，海外华人研究中心主办，《世界华商经济年鉴》杂志社编辑出版，在海内外公开发行的以世界华人华商经济活动大事纪为主题的，辅助其它实用有效的综合性题材为主要内容的年鉴出版物。</p>
          <p>出版许可证京期出证字第4593号。出版物号CN11—4593/F。广告许可证号，京海工商广字第0248号（1—1）ISSNISSN1009—9646。</p>  
          <p>《世界华商经济年鉴》已经走过了二十个年头，前全国人大常委会副委员长王光英、前全国政协副主席经叔平曾为年鉴题词：“加强华商交流，促进全球发展”、“华商共携手，迈向新世纪”。对年鉴的编辑出版工作给予了极大的关怀和支持。</p>
          <p>2024《世界华商经济年鉴》复刊及人物征集走访工作已经启动。版式设计为大十六开红色精装本；板块设计分四部分：第一部分，世界优秀华商代表人物；第二部分，国内外经济格局概况及产业特征；第三部分，国家关于经济发展的重要文献；第四部分，国内外经济专家、学者有指导性，有代表性的重要理论文章。将根据实际情况出版单册或上下册。以世界各国优秀的中华商人先进世迹为取材主体，展示在世界范围内具有一定影响力的优秀华人华商代表人物的创业之路、做人准则、经营之道、成功经验、社会贡献、高尚情操等。让世人知晓，让社会感动，让历史铭记，让后人传承。</p>
          <p>2024年，在高标准、高速度选材征集文稿的同时，拟定于年末在人民大会堂或深圳举行大型经济论坛，届时举办“世界华商慈善精神传承大会既国际项目合作交流大会”。并拟定每两年在世界各地知名城市举办规模广范的“世界华人华商国际交流合作大会”。同时，还将不定期的举办其它形式和内容的研讨会、专题论坛等。</p>
          <p>目前，一支具有一定规模和创新意识的专业化与国际接轨的调研、采访、编辑出版的工作队伍已经形成，将倾力打造一个世界华商华人经济文化合作交流的实用平台，创立一个世界华人华商投资、贸易、信息互动的集散信息港。助力世界华商华人国际投资、融资、贸易、文化经济、国际会务活动等一系列优质项目的实施与成功。</p>
          <p>华人华侨、中华儿女，为人类的和平发展与进步发挥了不可替代的积极作用，曾为祖国的繁荣昌盛做出了不可磨灭的贡献。前有陈嘉庚为国出资亲自创办厦门大学；也有爱国商人霍英东在抗美援朝期间面对西方禁运，不顾生命危险，冒死为大陆运送所需物资。改革开放后，他率先投资建设了内地第一家五星级酒店，向全球展示了中国改革开放的信念和决心。为了助力北京申奥，他不顾已经80高龄，四处奔走，宴请当时的国际奥委会主席。还有邵氏影业的创始人邵逸夫，他虽然投身的是娱乐事业，但却心系国家教育事业。从1985年起，他就开始扶持国内教育，先后建立了6000多所逸夫楼，遍布全国，总投资47亿之多。今天也有任正非誓死捍卫国家尊严，攀登民族企业科技高峰，为国争光。有宗庆后，坚守初心，心怀大爱，坚定的守护民族品牌。也有曹德旺高瞻远瞩，忧国忧民，倾心为社会打造一所福耀科技大学。他们的崇高壮举和对祖国厚爱的情怀，永远值得历史铭记，值得后人学习。</p>
          <p>《世界华商经济年鉴》编委会全体同仁，将以优秀的华商华人爱国精神为榜样，立足祖国，放眼世界；坚定不移的团结在以习近平总书记为核心的党中央周围，积极参与一带一路建设，贯彻落实党在新的历史时期重大方针政策和任务。为中华民族伟大复兴的中国梦做出积极贡献。</p>
          <p>为了更好更快的推动《世界华商经济年鉴》复刊出版的征集选材工作，拟在国内各省设立《世界华商经济年鉴》编委会联络处；设立港、澳、台联络处；成立“世界华商国际合作理事会”，为世界优秀的华商、华人树碑立传，彰显中华儿女屹立于世界商海精英之林的辉煌英姿。</p>
          <p class="introduct-date">《世界华商经济年鉴》编辑工作委员会</p>
          <p class="introduct-date">2024年1月10日</p>
        </div>
      </div>
    </div>
    <!-- 领导关怀 -->
    <div v-if="tabValue==1" class="content-max-w leader-module module-main" id="module2" ref="contentSectionRefs2">
      <div class="module-title-module">
        <div class="module-title">
          领导关怀
          <!-- <img src="@/assets/img/arrow.png" alt="" class="arrow-r" /> -->
          <div class="cut-line"></div>
        </div>
      </div>
      <ul class="flex-b">
        <li v-for="(item, index) in data.leaderList" :key="index" class="leader-item">
          <img  :src="require('@/assets/img/' + item.img)" alt="" class="leader-img"  :style="{'width':index == 1 ?'130px': index == 2 ?'190px':''}"/>
          <div class="flex-b leader-name" v-if="item.desc">
            <p>{{item.desc}}</p>
            <img src="@/assets/img/arrow-r-white.png" alt="" class="arrow-img"/>
          </div>
        </li>
      </ul>
    </div>
    <!-- 支持单位 -->
    <!-- <div v-if="tabValue==2" class="company-module">
      支持单位
      <div class="english-name">Supporting Organization</div>
      <div class="content-max-w">
        <swiper
        :modules="modules"
        style="width:100%"
        :loop="true"
        slidesPerView="auto"
        :spaceBetween="30"
        :autoplay="autoplay">
          <swiper-slide v-for="(item,index) in data.companyList" :key="index"  style="width:auto;">
            <span class="scroll-item">{{item}}</span> 
          </swiper-slide>
        </swiper>
      </div>
    </div> -->
    <!-- 编委会成员 -->
    <div v-if="tabValue==3" class="content-max-w editorial-board-module module-main" id="module3">
      <div class="module-title-module">
        <div class="module-title">
          编委会成员
          <div class="cut-line"></div>
        </div>
      </div>
      <ul class="user-module">
        <li v-for="(item, index) in data.bianwMemberList" :key="index" class="member-item">
          <!-- <img src="@/assets/img/avatar.png" alt="" class="avatar" /> -->
          <div>
            <p class="name editorial-name">
              <span class="job" :style="{'color': item.name == '马敬博' && item.job == '法务部副主任'?'#fff':''}">{{ item.job }}
                <span :style="{'color': item.job?'':'#fff'}">：</span>
              </span>
              <span style="white-space: nowrap;">
                <span style="white-space: nowrap;" v-if="item.name == '杨健'">
                    杨&nbsp;&nbsp;&nbsp;&nbsp;建
                  </span>
                  <span style="white-space: nowrap;" v-else>
                    {{ item.name }}
                  </span>
              </span>
            </p>
            <p class="desc">
              <!-- 成员简介 -->
            </p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 理事会成员 -->
    <div v-if="tabValue==4" class="editorial-board-outer-module module-main" id="module4">
      <div class="content-max-w editorial-board-module">
        <div class="module-title-module">
          <div class="module-title">
            理事会成员
            <div class="cut-line"></div>
          </div>
        </div>
        <div class="user-module">
          <div v-for="(item, index) in data.lishiMemberList" :key="index" class="member-item">
            <div v-if="!item.isCompany" style="display: flex">
              <!-- <img :src="require('@/assets/img/' + (item.avatar ?item.avatar :'avatar.png'))" alt="" class="avatar"/> -->
              <div>
                <p class="name">
                  <span class="job" style="display:inline-block;" :style="{'color': item.name == '卓言珍' || item.name == '杨健'?'#fff':''}">{{ item.job }}
                    <span :style="{'color': item.job ? '':'#fff'}">：</span>
                   </span>
                  <span style="white-space: nowrap;" v-if="item.name == '杨健'">
                    杨&nbsp;&nbsp;&nbsp;&nbsp;建
                  </span>
                  <span style="white-space: nowrap;" v-else>
                    {{ item.name }}
                  </span>
                </p>
                <p class="desc">
                  <!-- 成员简介 -->
                </p>
              </div>
            </div>
            <div v-else>
              <div style="display: flex; align-items: center">
                <!-- <img src="@/assets/img/company_logo.png" alt="" class="unit-logo"/> -->
                <div class="name">
                  <div>{{ item.name }}<span class="job">【{{ item.job }}】</span> </div>
                   <!-- 【{{ item.job }}】 -->
                </div>
              </div>
              <div>
                <p class="desc">
                  <!-- 公司简介 -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 工作动态 -->
    <div v-if="tabValue==5" class="content-max-w state-module module-main" id="module5">
        <div class="module-title-module">
          <div class="module-title">
            工作动态
            <div class="cut-line"></div>
          </div>
        </div>
        <!-- 第四部分动态 -->
        <div class="state-third-module state-one-module">
            <div class="float-left" style="width:380px;margin-right:0">
                <img src="@/assets/img/state_img8.jpg" style="width:380px;height:260px">
            </div>
            <p>
                <p class="state-title">《世界华商经济年鉴》编委会在深圳举行复刊启动座谈会</p>
                <p class="state-desc">7月7日下午，《世界华商经济年鉴》编委会在深圳举行复刊启动座谈会。四川、贵州、湖南等省级联络处负责人及部分在深的企业代表出席会议。国际大中华经贸促进会主席、《世界华商经济年鉴》理事会名誉理事长黄新建参加座谈会。</p>
                <p class="state-desc">座谈会上，与会人员踊跃发言，畅想《世界华商经济年鉴》复刊出版的深远历史意义和现实意义，一致认为，《世界华商经济年鉴》复刊出版是时代的召唤，是特殊的使命。</p>
                <p class="state-desc">《世界华商经济年鉴》编委会常务副总编黄楚晴介绍了该年鉴的政治属性及各板块的版性，并重申了复刊出版的主题思想。即：“聚世界华商，创中华伟业。让历史铭记，让后人传承。”紧紧团结在以习近平总书记为核心的党中央周围，积极参与一带一路建设；为中华民族伟大复兴做出积极的贡献。</p>
                <p class="state-desc">国际大中华经贸促进会主席、《世界华商经济年鉴》理事会名誉理事长黄新建表示：要把《世界华商经济年鉴》的复刊出版工作当做一项政治任务来完成，国际大中华经贸促进会作为战略合作单位，一定要不遗余力支持配合做好该年鉴的复刊及出版各项有关工作，发挥促进会独有的资源优势，积极推荐海内外优秀的华商企业家代表参与该年鉴的复刊出版工作。为海内外华人、华侨、华商牵线搭桥，铺好回家的路，共同促进祖国昌盛繁荣，促进世界和平。</p>             
            </p>
        </div>
        <div class="state-old-one-module">
          <div class="state-text">
            <div>
                <p class="state-title">{{ data.stateData.title }}</p>
                <p class="state-sec-title">《世界华商经济年鉴》2024精英版设计版式为大十六开红色精装本。代表人物、重点文章、海内外华商经济活动大世纪等文稿征集工作正式开始。
                   欢迎党和政府有关部门、宣传单位、企业家协会、海内外华人华商、优秀社团、专家、学者等，广泛参与，积极推荐，踊跃投稿。</p>
                <div class="flex-b state-con">
                    <div class="state-desc">
                      <div style="display:flex">
                        <div class="basic-text">
                          基本条件：
                        </div>
                        <div>
                          <p>一、热爱祖国，拥护中国共产党。</p>
                          <p>二、对社会和国家有突出贡献和责任感。</p>
                          <p>三、企业在本行业或一定范围内有足够的影响力及领军精神。</p>
                          <p>四、既有丰富的创业能力，又有奉献社会的大爱之心。</p>
                          <p>五、乐于支持本年鉴的编辑出版工作。</p>
                        </div>
                      </div>
                    </div>
                </div>
                <p class="state-date">《世界华商经济年鉴》编辑工作委员会 <span style="padding-left:10px">2024年02月07日</span></p>
            </div>
            </div>
            <div class='flex-c' style="justify-content:flex-start;">
              <img src="@/assets/img/state_img.png" class="state-img-l"/>
              <img src="@/assets/img/state_img2.png" class="state-img-l"/>
              <img src="@/assets/img/state_img3.png" class="state-img-l"/>
            </div>
        </div>
        <!-- 第二部分动态 -->
        <div class="state-second-module">
            <div class="img-box">
                <img src="@/assets/img/state-img4.png"/>
                <p>《世界华商经济年鉴》常务副总编黄楚睛 (中) 与国际大中华经贸促进会主席黄新建(右一)合影</p>
            </div>
            <div class="">
                <p class="state-title">《世界华商经济年鉴》编委会常务副总编黄楚晴女士在深圳会见国际大中华经贸促进会主席黄新建先生</p>
                <p class="state-desc">受总编辑委托，3月21日，《世界华商经济年鉴》常务副总编、《中国经济参要》内参调研部主任黄楚晴在深圳会见了国际大中华经贸促进会主席黄新建先生；并在黄新建先生陪同下，对深圳部分中小企业进行了走访调研。</p>
                <p class="state-desc">走访调研结束后，黄楚晴代表《世界华商经济年鉴》编委会、理事会向黄新建先生颁发了荣誉证书，聘请其担任《世界华商经济年鉴》理事会名誉理事长，助力《世界华商经济年鉴》2024精英版海外娇子代表人物的联络与征集工作。</p>
                <p class="state-date">2024年03月21日</p>
            </div>
        </div>
        <!-- 第三部分动态 -->
        <div class="state-third-module">
            <div class="float-left">
                <img src="@/assets/img/state_img5.png">
                <img src="@/assets/img/state_img6.png">
                <img src="@/assets/img/state_img7.png">
            </div>
            <p>
                <p class="state-title">《世界华商经济年鉴》编委会常务副总编黄楚晴到广东省联络处、深圳联络中心、香港联络中心走访调研</p>
                <p class="state-desc">受总编辑委托 ，3月22日，《世界华商经济年鉴》编委会常务副总编黄楚晴在事务部副主任林敬伟、法务部副主任杨健陪同下，走访看望了《世界华商经济年鉴》广东省联络处、深圳联络中心、香港联络中心部分工作人员。并在深圳宝安国际会展中心就如何开展好联络处、联络中心下一步具体工作召开了座谈会。</p>
                <p class="state-desc">会议由港、深联络中心负责人馬培民先生主持并致欢迎词，联络中心副主任范百灵博士代表港、深两地联络中心汇报了前期的有关筹备工作和下一步工作打算。</p>
                <p class="state-desc">听取汇报后，黄楚晴对广东省联络处、港、深两地联络中心初步开展的工作表示肯定，并希望及要求在今后的有关工作中，要紧紧的团结在以习近平总书记为首的党中央周围，认真学习、贯彻、落实全国“两会”会议精神，积极依法推进《世界华商经济年鉴》2024精英集各行、各业优秀代表人物的征集、推荐及文稿的组织工作。</p>
                <p class="state-desc">会上，黄楚晴代表《世界华商经济年鉴》编委会、理事会向馬培民先生颁发了香港联络中心有关负责人的任命书：法务部副主任兼广东省联络处主任杨健向范百灵女士颁发了深圳联络中心有关负责人的任命书。</p>
                <p class="state-desc">在坦率热情的气氛中，与会人员对今后如何更好开展相关工作进行了交流与探讨；并表示，今后要把《世界华商经济年鉴》的编辑出版等相关工作当做一种责任和使命来完成。不忘初心，砥砺前行。</p>
                <p class="state-desc">国际大中华经贸促进会主席、《世界华商经济年鉴》理事会名誉理事长黄新建先生陪同走访调研。</p>
                <p class="state-date">2024年03月22日</p>
            </p>
        </div>
        
    </div>
    <!-- 理事长 副理事长 理事 .... -->
    <div class="content-max-w">
      <div v-if="tabValue==7" class="module-title-module">
        <div class="module-title">
          理事、会员单位（中国区）
          <div class="cut-line"></div>
        </div>
      </div>
      <ul v-if="tabValue==7" class="min-height governing-unit-box">
        <li v-for="(item, index) in data.governingUnit" :key="index"style="margin-bottom:12px"> 
           {{item}}
        </li>
      </ul>
      <div v-if="tabValue==8" class="module-title-module">
        <div class="module-title">
          理事、会员单位（海外区）
          <div class="cut-line"></div>
        </div>
      </div>
      <div v-if="tabValue==8" class="min-height"></div>
      <!-- <ul v-if="tabValue==7" class="flex-b picg">
        <li class="picg-item" v-for="(item, index) in data.lishizhang2" :key="index" @mouseenter="handleMouseEnter(index,2)" @mouseleave="handleMouseLeave(index,2)">
          <img :src="require('@/assets/img/avatar/' + item)" alt="" style="max-width: 100%; height: auto"/>
          <ul class="hidden-desc" v-if="currentOuterIndex == index">
            <li><p></p>简介</li>
          </ul>
        </li>
      </ul> -->
      <!--专家服务 -->
      <div v-if="tabValue==6" class="module-title-module module-main" id="module6">
        <div class="module-title">
          专家与服务
          <div class="cut-line"></div>
        </div>
      </div>
      <ul v-if="tabValue==6" class="serve-module">
        <li v-for="(item, index) in data.serveList" :key="index">
          <div style="display:flex;justify-content:flex-start">
            <p class="title">{{ item.name }}：</p>
            <p class="desc">
              {{item.desc}}
              <span style="color:#d50200;cursor:pointer" v-if="item.name =='姚文初'" @click="showModal=true">详情</span>
              <img src="@/assets/img/red_arrow.png" v-if="item.name =='姚文初'" style="width:8px;height:12px;margin-left:4px"/>
            </p>
          </div>
          <!-- <p><span class="label" v-for="(itemL, indexL) in item.label" :key="indexL">{{itemL}}</span></p> -->
        </li>
      </ul>
    </div>
    <!-- 章程 各省联络处 广东联络中心 香港联络中心 -->
    <div class="link-module-outer">
      <div  class="content-max-w">
        <!-- 章程 -->
        <!-- <div v-if="tabValue==9" class="module-title-module module-main" id="module7">
          <div class="module-title">
            章程
            <div class="cut-line"></div>
          </div>
        </div> -->
        <div v-if="tabValue==9" class="introduct-module module-main rule-box" id="module1" ref="contentSectionRefs1">
          <div class="content-max-w introduction">
            <div class="introduction-content">
                <div style="font-size: 24px;color: #920407;font-weight:bold">聚世界华商，创中华伟业</div>
                <div style="font-size: 22px;color: #000;margin-top:8px">世界华商国际合作理事会</div>
                <div class="flex-c title">
                    <div class="title-text">章程</div>
                </div>
              <div class="english-name" style="margin-bottom: 16px">
                <!-- INTRODUCTION -->
              </div>
              <p>第一章</p>
              <p>名称：世界华商国际合作理事会。</p>
              <p>The World Overseas Chinese Businessman of International Cooperation Board of Directors (WOCBIBD)</p>
              <p>第二章</p>
              <p>发起单位：《世界华商经济年鉴》杂志社。《世界华商经济年鉴》编委会。《世界华商经济年鉴》工作理事会。</p>
              <p>第三章</p>
              <p>年鉴简介：《世界华商经济年鉴》是由中国社会科学院主管，海外华人研究中心主办，《世界华商经济年鉴》杂志社编辑出版，在海内外公开发行的以世界华人华商经济活动大事纪为主题的，辅助其它实用有效的综合性题材为主要内容的年鉴出版物。</p>
              <p>出版许可证京期出证字第4593号。出版物号CN11—4593/F。广告许可证号，京海工商广字第0248号（1—1）ISSNISSN1009—9646。</p>
              <p>《世界华商经济年鉴》已经走过了二十个年头，前全国人大常委会副委员长王光英、前全国政协副主席经叔平曾为年鉴题词：“加强华商交流，促进全球发展”、“华商共携手，迈向新世纪”。对年鉴的编辑出版工作给予了极大的关怀和支持。</p>      
              <p>2024年，《世界华商经济年鉴》复刊筹备走访工作已经启动，版式设计为大十六开红色精装本。内容 以世界各国优秀的中华商人先进世迹为取材主体，展示在世界范围内具有一定影响力的优秀华人华商代表人物的创业之路、做人准则、经营之道、成功经验、社会贡献、高尚情操等。让世人知晓，让社会感动，让历史铭记，让后人传承。</p>
              <p>第四章</p>
              <p>组建世界华商国际合作理事会。</p>
              <p>在做好《世界华商经济年鉴》选材、编辑、出版的同时，依托强大的祖国、依托国内外强大的专家、学者、企业家的力量，拉动吸引全世界的爱国华商、华人、华侨互联互动，打造一个出国考察、传承文化、旅游观光、相互学习、共同提升、互利共赢的国际大舞台。开展各种有意义的经济活动，文化活动，互访互助活动，促进海内外华商企业互通有无，互助共勉，联手共创，繁荣发展。同时借助好国内各个领域强大的国家层面的专家学者智慧，解读国家政策，为各类有需求的企业把诊号脉，献计献策，为企业保驾护航。积极推动新经济发展，发挥新质生产力的强大动力，促进祖国经济繁荣昌盛，为促进世界和平，建立人类命运共同体伟大事业发挥积极的作用。</p>
              <p>第五章</p>
              <p>会员制：世界华商国际合作理事会欢迎所有关心热爱本会工作宗旨的爱国华商华人成为会员、理事会员。入选年鉴的代表人物和企业优先。使之在支持《世界华商经济年鉴》选材、编辑、出版工作的同时，积极参与世界华商国际合作理事会组织的各种经济交流活动，文化交流活动，艺术交流活动，国际贸易活动，招商引资活动，人文互访互助活动及爱心慈善活动等。</p>
              <p>第六章</p>
              <p>会费。成为本会会员，首先要按着国家有关规定，象征性的缴纳不同级别会员的会费。用于支持《世界华商经济年鉴》的出版工作及国际合作理事会开展的各项经济、文化、贸易、科技推广、人文交流及相关公益活动。</p>
              <p>第七章</p>
              <p>会费的管理及使用。</p>
              <p>理事会组建常务委员会，设立秘书处，建立严格的内部财务管理机制，由秘书处牵头，成立财务监管工作小组。定期的向理事会常委会和会员代表大会报告财务管理使用情况。主动接受全体会员的监督。</p>
              <p>第八章</p>
              <p>会员代表大会制度。</p>
              <p>世界华商国际合作理事会拟定每年召开一次会员代表大会。听取理事会常委会相关工作汇报。审议完善相关工作。</p>
              <p>第九章</p>
              <p>理事会建设。</p>
              <p>世界华商国际合作理事会首届常务委员会成员由《世界华商经济年鉴》编委会提名推荐组成。从第二届开始，由会员代表大选举产生。</p>
              <p>第十章</p>
              <p>会标。</p>
              <p>世界华商国际合作理事会标识待设计当中。</p>
              <p>第十一章</p>
              <p>理事会活动。</p>
              <p>理事会拟定每两年在世界各地知名城市举办规模广范的“世界华人华商国际合作交流大会”。同时，还将不定期的举办其它形式和内容的研讨会、专题论坛等， 将倾力打造一个世界华商、华人经济文化合作交流的实用平台，创立一个世界华人、华商投资、贸易、信息互动的集散信息港。助力世界华商华人国际投资、融资、贸易、文化经济、国际会务活动等一系列优质项目的实施与落地。</p>
              <p>华人华侨、中华儿女，为人类的和平发展与进步发挥了不可替代的积极作用，曾为祖国的繁荣昌盛做出了不可磨灭的贡献。前有陈嘉庚为国出资亲自创办厦门大学；也有爱国商人霍英东在抗美援朝期间面对西方禁运，不顾生命危险，冒死为大陆运送所需物资。改革开放后，他率先投资建设了内地第一家五星级酒店，向全球展示了中国改革开放的信念和决心。为了助力北京申奥，他不顾已经80高龄，四处奔走，宴请当时的国际奥委会主席。还有邵氏影业的创始人邵逸夫，他虽然投身的是娱乐事业，但却心系国家教育事业。从1985年起，他就开始扶持国内教育，先后建立了6000多所逸夫楼，遍布全国，总投资47亿之多。今天也有任正非誓死捍卫国家尊严，攀登民族企业科技高峰，为国争光。有宗庆后，坚守初心，心怀大爱，坚定的守护民族品牌。也有曹德旺高瞻远瞩，忧国忧民，倾心为社会打造一所福耀科技大学。他们的崇高壮举和对祖国厚爱的情怀，永远值得历史铭记，值得后人学习。</p>
              <p>世界华商国际合作理事会全体同仁将以优秀的华商华人爱国精神为榜样，立足祖国，放眼世界；坚定不移的团结在以习近平总书记为核心的党中央周围，积极参与一带一路建设，贯彻落实党在新的历史时期重大方针政策和任务。汇聚理事会全体成员的智慧和力量，为中华民族伟大复兴做出积极贡献。</p>
              <p class="introduct-date">世界华商国际合作理事会</p>
              <p class="introduct-date">2024年10月8日</p>
            </div>
          </div>
        </div>



        <!-- <ul v-if="tabValue==9" class="flex-b serve-module ">
          <li v-for="(item, index) in data.serveList" :key="index" class="finance-item">
            <div>
              <p class="title">标题</p>
              <p class="desc" style="margin-bottom:12px">
                正文
              </p>
            </div>
            <p style="font-size: 12px;">
              2024年3月12日
            </p>
          </li>
        </ul> -->
        <!-- 联络处（中心） -->
        <div v-if="tabValue==10">
            <div class="module-title-module module-main" id="module8">
                <div class="module-title">
                    各省联络处（中心）
                    <div class="cut-line"></div>
                </div>
            </div>
            <ul class="link-address">
                <li class="link-office-item-s" :style="{'margin-bottom': item.isEnd ? '14px':''}" v-for="(item, index) in data.xgAddrList" :key="index">
                    <p class="title" v-if="item.unit" :style="{'color': item.isSame?'#fff':''}">{{ item.unit }}：</p>
                    <div style="display:flex;">
                    <!-- <img :src="require('@/assets/img/' + (item.addr ? item.addr : 'avatar.png'))" alt="" class="avatar"/> -->
                    <div>
                        <p class="name">{{item.name}}</p>
                        <!-- <p class="address">地址：</p>
                        <p class="address">电话：</p> -->
                    </div>
                    </div>
                </li>
            </ul>
        </div>
        
        <!-- 经济与金融 -->
        <div v-if="tabValue==11" class="module-title-module module-main">
          <div class="module-title">
            经济与金融
            <div class="cut-line"></div>
          </div>
        </div>
        <div class="min-height" v-if="tabValue==11"></div>
         <!-- 联系方式 -->
         <div v-if="tabValue==12" class="module-title-module module-main">
          <div class="module-title">
            联系方式
            <div class="cut-line"></div>
          </div>
        </div>
        <div class="min-height link-box" v-if="tabValue==12">
            <div class="address-box">
                <p>地址：</p> 
                <span>北京市西城区复兴门内大街45号4号楼。</span> 
            </div>
            <div class="phone-box">
                <p>联系电话：</p> 
                <span>18810104158</span>
            </div>
        </div>
        <!-- 备注 -->
        <div v-if="tabValue==13" class="module-title-module module-main">
          <div class="module-title">
            备注
            <div class="cut-line"></div>
          </div>
        </div>
        <div class="min-height" v-if="tabValue==13"></div>
        <!-- 支持单位 -->
        <div v-if="tabValue==2" class="module-title-module module-main">
          <div class="module-title">
            支持单位
            <div class="cut-line"></div>
          </div>
        </div>
        <div class="min-height" v-if="tabValue==2"></div>
      </div>
    </div>
    <!-- 联系电话-->
    <!-- <div class="link-b module-main" id="module9">
      <div class="link-num flex-c">
        <img src="@/assets/img/phone.png" />
        18810104158
      </div>
      <p class="link-tip">遇到问题请第一时间联系到官方网站联系我们</p>
    </div> -->
    <div class="footer-info">
      <div class="content-max-w">
       
        <ul class="footer-office" >
          <li>
            主管:中国社会科学院
            <p class="cute-line"></p>
          </li>
          <li>
            主办: 海外华人研究中心
            <p class="cute-line"></p>
          </li>
          <li>
            编辑出版:《世界华商经济年鉴》杂志社
          </li>
        </ul>
        <!-- <ul class="footer-office">
          <li v-for="(item, index) in data.footerAddrlist" :key="index">
            {{ item }}
            <p class="cute-line" v-if="index + 1 < data.footerAddrlist.length"></p>
          </li>
        </ul> -->
        <ul class="flex-c footer-blogroll" style="margin-top: 0">
          <li v-for="(item, index) in data.footerAddrlist" :key="index" >
            <span  v-if="item.url">
                {{index==0?"友情链接：":''}}<a :href="item.url" target="_blank">{{ item.name }}</a>
            </span>
            <span v-else>{{ item.name }}</span>
            <p class="cute-line" v-if="index + 1 < data.footerAddrlist.length"></p>
          </li>
        </ul>
        <ul class="flex-c footer-blogroll" style="margin-top: 0">
          <li v-for="(item, index) in data.footerAddrlist3" :key="index" >
            <span>
                <a :href="item.link" target="_blank">{{ item.name }}</a>
            </span>
            <p class="cute-line" v-if="index + 1 < data.footerAddrlist.length"></p>
          </li>
        </ul>
        <ul class="flex-c footer-office-info" style="padding-bottom: 0">
          <li style="" v-for="(item, index) in data.footerInfolist1" :key="index">
            {{ item }}
            <p class="cute-line" v-if="index + 1 < data.footerInfolist1.length"></p>
          </li>
        </ul>
        <ul class="flex-c footer-office-info" style="margin-top: 0">
          <li v-for="(item, index) in data.footerInfolist2" :key="index" >
            <span v-if="item.indexOf('备案') > -1">
              <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{ item }}</a>
            </span>
            <span v-else>{{ item }}</span>
            <p class="cute-line" v-if="index + 1 < data.footerInfolist2.length"></p>
          </li>
        </ul>
        <div class="footer-title flex-b">
          <img src="@/assets/img/footer-line-l.png" class="footer-line-l"/>
          <div style="min-width: 150px">世界华商经济年鉴</div>
          <img src="@/assets/img/footer-line-r.png" class="footer-line-l"/>
        </div>
      </div>
    </div>
    <!-- 个人简介 -->
    <div class="modal-backdrop" v-if="showModal">

    </div>
    <div class="modal-con" v-if="showModal">
        <div class="flex-b modal-title">
            <div>
                姚文初老师简介
            </div>
            <div @click="showModal = false" class="modal-close">
               <img src="@/assets/img/close.png" class="modal-img"/>
                关闭
            </div>
        </div>
        <div class="user-desc">
            <p class="user-name">姚文初</p>
            <p> 1990 年毕业于华中农业大学农学系，同年调入农业部工作，历任农业农村部直属机关团委副书记、农业农村部规划设计研究院办公室副主任、基建与资产管理处副处长，服务中心副主任等。</p>
            <p>现任农业农村部规划设计研究院农业项目咨询专家，全国绿色产业促进工作委员会专家委员，兼任农业农村部、科技部、财政部多项农业项目的评审专家。</p>
            <p> 主持和参加了数十项大型农业项目规划、项目建议书、可行性研究报告。具有代表性的有：</p>
            <p>西藏自治区一江两河流域中部地区农业农村经济发展总体规划；</p>
            <p>长江三峡库区中部地区农业和农村经济发展总体规划；</p>
            <p>长江中上游生态系统建设总体规划；</p>
            <p>黄河中上游生态系统建设总体规划；</p>
            <p>深圳光明华侨畜牧场经济发展总体规划；</p>
            <p>大同市大同县观光农业园区规划;</p>
            <p>浙江千岛湖观光农业园区总体规划。</p>
            <p>长江中上游生态系统建设总体规划；</p>
            <p>另外，还参加了全国种子工程产业化、商业化项目建议书、西藏高寒牧区游牧民定居点建设项目可行性研究、西藏自治区那曲地区草地建设项目可行性研究、西藏自治区那曲地区土特产加- 1 -工项目可行性研究、新疆维吾尔自治区克拉玛依 80 万亩生态农业建设项目可行性研究等等。</p>
            <p>其中《深圳光明华侨畜牧场经济发展总体规划》获中国工程咨询协会国家级一等奖，此规划招商引资达 57 亿元。</p>     
            <p>同时长期从事中央农村政策的研究、解读，多次受邀担任农业农村部干部培训中心中央惠农政策解读培训班授课专家，曾受邀请赴香港出席亚洲农产品博览会并作为嘉宾给博览会做中央惠农政策专题讲座并获得高度评价！还在北京、天津、乌鲁木齐、呼和浩特、邯郸、深圳、厦门、上海、杭州、桂林、重庆、张家界、长沙、赣州、香港等多地举行解读中央惠农政策巡回报告会，还被聘为中组部、农业部举办的农村致富带头人培训班培训师。接受过中央电视台和地方台多次惠农政策和产业专题采访。</p>       
        </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  watch,
  nextTick,
  defineComponent,
} from "vue";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Autoplay} from 'swiper/modules';
import ModuleTitle from "../components/ModuleTitle.vue";
const { proxy } = getCurrentInstance();
const currentMenuIndex = ref(0);
const currentIndex = ref(-1);
const currentOuterIndex = ref(-1);
const showModal = ref(false)
const data = reactive({
//   menuList: [
//     { title: "关于我们", id: "module1" },
//     { title: "领导关怀", id: "module2" },
//     { title: "编委会", id: "module3" },
//     { title: "理事会", id: "module4" },
//     { title: "工作动态", id: "module5" },
//     { title: "专家与服务", id: "module6" },
//     { title: "章程", id: "module7" },
//     { title: "联络处", id: "module8" },
//     { title: "联系我们", id: "module9" },
//   ],
menuList: [
    { title: "简介", id: "module1" },
    { title: "领导关怀", id: "module2" },
    { title: "支持单位", id: "module3" },
    { title: "编委会成员", id: "module3" },
    { title: "理事会成员", id: "module4" },
    { title: "工作动态", id: "module5" },
    { title: "专家与服务", id: "module6" },
],
twoMenuList:[
    { title: "理事会员单位（中国区）", id: "module7"},
    { title: "理事会员单位（海外区）", id: "module8"},
    //{ title: "专家与服务", id: "module9"},
    { title: "章程", id: "module10"},
    { title: "联络处（中心）", id: "module11"},
    { title: "经济与金融", id: "module9"},
    { title: "联系方式", id: "module12"},
    { title: "备注", id: "module12" }],
companyList: [
    "中华全国工商联合会联络部",
    "中国社会经济文化交流协会",
    "中国企业家协会",
    "中国国际贸易促进会",
    "国际大中华经贸促进会",
    "《中国经济参要》内参编委会",
    "《中国军民融合要参》编委会",
],
leaderList: [
    {img:'leader2.png',desc:'前全国人大常委会副委员长王光英为年鉴题词'},
    {img:'leader3.png',desc:'前全国政协副主席经叔平为年鉴题词'},
    {img:'leader1.png',desc:'世界经济与政治研究所原所长李琮为年鉴提词'},
],
bianwMemberList: [
    { name: "华文", job: "编委会主任" },
    { name: "黄丽", job: "常务副主任" },
    { name: "华文", job: "总编辑" },
    { name: "黄楚晴", job: "常务副总编" },
    { name: "段心强", job: "副总编辑"},
    { name: "孙东飞", job: "编辑部主任" },
    { name: "郑新军", job: "策划部主任" },
    { name: "尤玲玲", job: "调研部副主任" },
    { name: "李军民", job: "联络部副主任" },
    { name: "林静伟", job: "事务部副主任" },
    { name: "李平煜", job: "美编部副主任" },
    { name: "张雨梁", job: "办公室主任" },
    { name: "杨健", job: "法务部副主任" },
    { name: "马敬博", job: "法务部副主任" },
    { name: "陈万书", job: "专家服务部副主任" },
    { name: "龙晓晓", job: "会务（课题）部副主任" },
    { name: "苏高强", job: "课题组组长" },
],
lishiMemberList: [
    { name: "华文", job: "理事长", avatar:''},
    { name: "黄新建", job: "名誉理事长", avatar:'avatar/hxj.png'},
    { name: "黄楚晴", job: "常务副理事长", avatar:''},
    { name: "钟志敏", job: "副理事长", avatar:'' },
    { name: "卓言珍", job: "副理事长", avatar:'' },
    { name: "杨健", job: "副理事长", avatar:''},
    { name: "黄楚晴（兼）", job: "秘书长", },
],
stateData: {
    title: "2024《世界华商经济年鉴》精英集入选人物基本要求",
    desc: "一、热爱祖国，拥护中国共产党。二、对社会和国家有突出贡献和责任感。三、企业在本行业或一定范围内有足够的影响力及领军精神。四、既有丰富的创业能力，又有奉献社会的大爱之心。五、乐于支持本年鉴的编辑出版工作。",
    list: [
      "热爱祖国，拥护中国共产党。",
      "对社会和国家有突出贡献和责任感。",
      "企业在本行业或一定范围内有足够的影响力及领军精神。",
      "既有丰富的创业能力，又有奉献社会的大爱之心。",
    ],
},
lishizhang: [
    "lishizhang1.png",
    "f_lishizhang1.png",
    "lishi1.png",
    "lishi1.png",
],
lishizhang2: [
    "lishizhang2.png",
    "f_lishizhang2.png",
    "lishi2.png",
    "lishi2.png",
],
governingUnit:[
    '山东联创优品环保科技有限公司',
    '河南省月明山旅游开发有限公司',
    '宁波财神谷旅游发展有限公司',
    '黄埔再生资源利用有限公司',
    '北京华夏艺星国际文化传媒有限公司',
    '宝蓝集团股份有限公司',
    '福建雄丰进出口贸易有限公司'],
serveList: [
    {name:'姚文初', job:'农业农村部规划设计院',desc:'农业农村部规划设计研究院重点项目咨询专家，农业农村部、科技部、财政部重点农业项目评审专家（博士）',label:['博士']},
    {name:'景朝阳', job:'国家发改委国际合作中心',desc:'国家发改委国际合作中心重大项目指导专家（博士后）',label:['博士后']},
    {name:'杨敬华', job:'中国农业科学院农业经济与发展研究所',desc:'中国农业科学院农村经济与发展研究所博士、研究员、研究生导师。',label:['博士','研究员','研究生导师']},
],

//   addrList: [
//     { unit: "湖南省联络处", name:'张慧',addr: "", avatar:'' },
//     { unit: "四川省联络处", name:'林静伟',addr: "", avatar:'avatar/ljw.png' },
//     { unit: "辽宁省联络处", name:'李兆宁',addr: "", avatar:'avatar/lzn.png'},
//     { unit: "黑龙江省联络处", name:'廉成山',addr: "",avatar:''},
//   ],
xgAddrList: [
    { unit: "广东省联络处", name:'杨健（主任）', addr: "" },
    { unit: "广东省联络处", name:'江建新（副主任）', addr: "" ,isSame:1},
    { unit: "广东省联络处", name:'杨宏孝（副主任）', addr: "" ,isSame:1},
    { unit: "广东省联络处", name:'孙正谦、李明、孔均明、陈伟文、陈基显（助理）', addr: "",isSame:1,isEnd:1},

    { unit: "湖南省联络处", name:'张慧（主任）', addr: "" },
    { unit: "湖南省联络处", name:'罗利顺（副主任）', addr: "" ,isSame:1},
    { unit: "湖南省联络处", name:'方晓敏（助理）', addr: "" ,isSame:1,isEnd:1},
    { unit: "四川省联络处", name:'林静伟（主任）', addr: "avatar/ljw.png" },
    { unit: "四川省联络处", name:'罗 东（常务副主任）', addr: "avatar/ljw.png",isSame:1 },
    { unit: "四川省联络处", name:'王科、殷未（副主任）', addr: "avatar/ljw.png" ,isSame:1},
    { unit: "四川省联络处", name:'王鈺尧、钟健灵、刘园、胡君（助理）', addr: "avatar/ljw.png",isSame:1,isEnd:1},

    { unit: "贵州省联络处（筹）", name:'汤信伟（主任）', addr: "avatar/ljw.png" },
    { unit: "贵州省联络处（筹）", name:'余兴龙（副主任）', addr: "avatar/ljw.png",isSame:1 },
    { unit: "贵州省联络处（筹）", name:'李无（副主任）', addr: "avatar/ljw.png" ,isSame:1},
    { unit: "贵州省联络处（筹）", name:'胡君（副主任）', addr: "avatar/ljw.png",isSame:1,isEnd:1},

    { unit: "辽宁省联络处", name:'李兆宁（主任）', addr: "avatar/lzn.png" ,isEnd:1},
    { unit: "黑龙江省联络处", name:'廉成山（主任）', addr: "" ,isEnd:1},
    { unit: "香港联络中心", name:'马培民（主任）', addr: "" },
    { unit: "香港联络中心", name:'范百灵（副主任）', addr: "" ,isSame:1},
    { unit: "香港联络中心", name:'王家辉（海外事务部部长）', addr: "" ,isSame:1},
    { unit: "香港联络中心", name:'黄伟贤（宣传部部长）', addr: "" ,isSame:1},
    { unit: "香港联络中心", name:'黄静（联络部部长）', addr: "" ,isSame:1,isEnd:1},

    { unit: "深圳联络中心", name:'马培民（主任）', addr: "" },
    { unit: "深圳联络中心", name:'范百灵（副主任）', addr: "" ,isSame:1},
    { unit: "深圳联络中心", name:'马欣（副主任）', addr: "" ,isSame:1},
    { unit: "深圳联络中心", name:'冷雪松（办公室主任）', addr: "" ,isSame:1},
    { unit: "深圳联络中心", name:'张志强（顾问）', addr: "" ,isSame:1,isEnd:1},

    { unit: "湖北省联络处（筹）", name:'尹述鹏（主任）', addr: "" },
],
szAddrList: [],
gdAddrList: [{name:'名称', addr: "" },],
footerAddrlist: [
    {name: '外交部', url: 'http://www.fmprc.gov.cn/'},
    {name: '国家发展和改革委员会', url: 'http://www.ndrc.gov.cn/'},
    {name: '教育部', url: 'http://www.moe.gov.cn/'},
    {name: '科学技术部', url: 'http://www.most.gov.cn/'},
    {name: '工业和信息化部', url: 'https://www.miit.gov.cn/'},
    {name: '公安部', url: 'http://www.mps.gov.cn/n16/index.html'},
    {name: '财政部', url: 'http://www.mof.gov.cn/'},
    {name: '人力资源和社会保障部', url: 'http://www.mohrss.gov.cn/'},
    {name: '商务部', url: 'http://www.mofcom.gov.cn/'},
    {name: '农业农村部', url: 'http://www.moa.gov.cn/'},
    {name: '安全部', url: ''}
],
footerAddrlist3: [],
footerInfolist1: [
    "联系方式：18810104158",
    "地址：北京市西城区复兴门内大街45号4号楼",
    " 邮编：100801",
],
footerInfolist2: [
    "出版许可证京期出证字第4593",
    "出版物号CN11—4593\F",
    "广告许可证号，京海工商广字第0248号（1—1）ISSNISSN1009—9646",
    "工信部备案许可证编号：京ICP备2024052764号"
],
});
const tabValue = ref(0)
const currentActive = ref(null);
const isFixed = ref(false);
const topBgH = ref(0);
const scrollInterval = ref(null);
const contentSections = ref([]);
const scrollTop = ref("0");
const isScroll = ref(true);
const modules = [Autoplay]
const autoplay = ref({
  delay: 2000,
  disableOnInteraction: false
})
const isScrollBottom = ref(false)

const handleMouseEnter = (index,type) => {
    if(type == 1){
        currentIndex.value = index;
    }else{
        currentOuterIndex.value = index;
    }
}
const handleMouseLeave = () => {
    currentIndex.value = currentOuterIndex.value = -1;
}
const scrollToSection = (index, targetId) => {
    console.log(index);
    tabValue.value = index
    var num = 1;
    if (index < currentMenuIndex.value) {
      num = -1;
    }
    currentMenuIndex.value = index;
    // isScroll.value = false;
    // clearInterval(scrollInterval.value);
    // const targetElement = document.getElementById(targetId);
    // if (targetElement) {
    //     var step = 0;
    //     var yushu = 0;
    //     if (document.documentElement.scrollTop == 0) {
    //         step = parseInt(targetElement.offsetTop / 50);
    //         yushu = targetElement.offsetTop % 50;
    //     } else {
    //         step = parseInt( Math.abs(document.documentElement.scrollTop - targetElement.offsetTop) / 50);
    //         yushu = Math.abs(document.documentElement.scrollTop - targetElement.offsetTop) % 50;
    //     }
    //     let targetScrollTop = yushu > 0 ? targetElement.offsetTop - yushu : targetElement.offsetTop;
    //     scrollInterval.value = setInterval(() => {
    //         if (num > 0) {
    //             document.documentElement.scrollTop += step;
    //         } else {
    //             document.documentElement.scrollTop -= step;
    //         }
    //         if (document.documentElement.scrollTop >= targetScrollTop && num > 0) {
    //             document.documentElement.scrollTop -= 60;
    //             document.documentElement.scrollTop += yushu;
    //             nextTick(() => {
    //                 isScroll.value = true;
    //             });
    //             clearInterval(scrollInterval.value);
    //         } else if (document.documentElement.scrollTop < targetScrollTop && num < 0) {
    //             document.documentElement.scrollTop += yushu;
    //             document.documentElement.scrollTop -= 50;
    //             nextTick(() => {
    //                 isScroll.value = true;
    //             });
    //             clearInterval(scrollInterval.value);
    //         }else if(isScrollBottom.value){
    //             nextTick(() => {
    //                 isScroll.value = true;
    //             });
    //             clearInterval(scrollInterval.value);
    //         }
    //     }, 5);
    // }
}
const scrollToTop = () => {
    scrollTop.value = document.documentElement.scrollTop;
}
const handleScroll = () => {
    const container = document.querySelector('.scroll-container');
    if (container) {
        const { scrollHeight } = container;
        isScrollBottom.value = scrollHeight - document.documentElement.scrollTop === window.innerHeight;
    }
}
onMounted(() => {
    // window.addEventListener("scroll", scrollToTop);
    // window.addEventListener('scroll', handleScroll);
    contentSections.value = Array.from(document.getElementsByClassName("module-main"));
    setTimeout(() => {
        topBgH.value = Array.from(document.getElementsByClassName("topBg"))[0].clientHeight;
    }, 100);
});
onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
watch(scrollTop, (newv) => {
    isFixed.value = scrollTop.value > Number(topBgH.value) ? true : false
    if (!isScroll.value) {
        return;
    }
    // console.log('isScrollBottom.value',isScrollBottom.value);
    if (scrollTop.value + 60 < contentSections.value[1].offsetTop) {
        currentMenuIndex.value = 0;
        return;
    } else if (scrollTop.value + 60 > contentSections.value[1].offsetTop && scrollTop.value + 60 < contentSections.value[2].offsetTop) {
        currentMenuIndex.value = 1;
        return;
    } else if (scrollTop.value + 60 > contentSections.value[2].offsetTop && scrollTop.value + 60 < contentSections.value[3].offsetTop) {
        currentMenuIndex.value = 2;
        return;
    } else if (scrollTop.value + 60 > contentSections.value[3].offsetTop && scrollTop.value + 60 < contentSections.value[4].offsetTop) {
        currentMenuIndex.value = 3;
        return;
    } else if (scrollTop.value + 60 > contentSections.value[4].offsetTop && scrollTop.value + 60 < contentSections.value[5].offsetTop) {
        currentMenuIndex.value = 4;
        return;
    } else if (scrollTop.value + 60 > contentSections.value[5].offsetTop && scrollTop.value + 60 < contentSections.value[6].offsetTop) {
        currentMenuIndex.value = 5;
        return;
    } else if (scrollTop.value + 60 > contentSections.value[6].offsetTop && scrollTop.value + 60 < contentSections.value[7].offsetTop) {
        currentMenuIndex.value = 6;
        return;
    } else if (scrollTop.value + 60 > contentSections.value[7].offsetTop && scrollTop.value + 60 < contentSections.value[8].offsetTop) {
        currentMenuIndex.value = 7;
        return;
    }
});
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
.header {
  width: 100%;
  background: url("@/assets/img/header-bg.png");
}
.modellist_container {
  width: 100%;
  height: 100%;
}

.topBg {
  position: relative;

    .blnking-text {
        position: absolute;
        top: 88.5%;
        bottom: 2.5%;
        left: 50%;
        transform: translate(-50%, 0);
        width:40%;
        display:flex;
        align-items:center;
        // height:38px;
        // background: url("@/assets/img/page_title.gif");
        // background-size:100% 100%;
    }
    
    @keyframes fade {
        0% {
           opacity: 0.3; /* 初始状态完全透明 */
        }
        50% {
          opacity: 1; /* 中间状态完全显示 */
        }
        100% {
          opacity: 0.3; /* 结束状态完全透明 */
        }
    }
    .blnking-text-img{
        opacity: 0; 
        animation: fade 5s infinite;
    }
}

.flex-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-c {
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-max-w {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}
.point {
    cursor: pointer;
}
.menu-tab {
    width: 100%;
    height: 60px;
    background: #f5f5f7;
    border-radius: 0px 0px 0px 0px;
    font-size:18px;
    font-weight: 600;
    color: #000000;
    // border-bottom: 1px #BFBFBF solid

    li:hover {
       color: #d50200; /* 鼠标悬停时的背景颜色 */
    }
}
.menu-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.check-menu {
    color: #d50200;
    position: relative;
}
.check-line {
    display: inline-block;
    position: absolute;
    width: 45px;
    height: 4px;
    background: #d50200;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, 0);
}
.list-wrap {
    width: 1200px;
}
.english-name {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;
    padding-left: 6px;
    // margin-bottom: 20px;
}
.no-scrollbar {
    -ms-overflow-style: none; /* IE10+浏览器 */
    scrollbar-width: none; /* Firefox浏览器 */
}
// 简介
.introduct-module {
    background: url("@/assets/img/introduce_bg.png");
    background-size: cover;

    .introduction {
        padding: 20px 0 45px;

        .title {
            font-weight: 600;
            font-size: 32px;
            color: #333333;
            margin-bottom: -8px;
            padding-top: 20px;
      
            .title-text {
                padding: 0 18px;
                // background: url("@/assets/img/text_bg.png");
                position: relative;
                z-index: 9;
            }
        }
        .line {
            width: 113px;
            height: 3px;
        }
        .introduction-content {
            border: 2px dashed #e8080e;
            padding: 42px 38px 26px;
        }
        p {
            text-indent: 2em;
            text-align: left;
            line-height: 30px;
            font-size: 16px;
        }
        .introduct-date {
            text-align: right;
        }
    }
}
//章程
.rule-box{
    background:none;
    .introduction .introduction-content{
        border:none;
        padding: 30px 38px 26px;
    }
}
// 公司
.company-module {
    background: url("@/assets/img/company_bg.png");
    background-size: cover;
    padding: 24px 0;
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    // margin-bottom:33px;
    margin-bottom: 0px;
  
    .english-name {
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 34px;
    }
  
    .scroll-item {
        padding: 17px 55px;
        margin-right: 20px;
        display: inline-block;
        font-size: 18px;
        color: #31363a;
        font-weight: 500;
        background: #fff;
        border-radius: 4px;
    }
}
.module-title-module {
    .module-title {
        font-weight: 600;
        font-size: 18px;
        color: #505050;
        display: flex;
        align-items: flex-end;
        padding: 30px 0 10px;
        border-bottom: 1px dashed #d2b7b8;
        margin-bottom:30px;
    
        .arrow-r {
          width: 18px;
          height: 18px;
          margin: 0 10px 4px 7px;
        }
        .cut-line {
        //   height: 100%;
        //   flex: 1;
        //   border-bottom: 1px dashed #d2b7b8;
        //   margin-bottom: 4px;
        }
    }
    .english-name {
        text-align: left;
        font-size: 14px;
    }
}

.leader-module {
  // margin-bottom:76px;
  margin-bottom: 0px;
  padding-bottom: 30px;

  .leader-item {
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #dcdcdc;
    position: relative;
    width: 30%;
    padding:15px 0;

    .leader-img {
      width: 156px;
      height: 286px;
    }

    .leader-name {
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      padding: 10px 10px;
      box-sizing: border-box;
      font-size:14px;
      letter-spacing: 1px;
      p {
        text-align: left;
      }

      .arrow-img {
        width: 15px;
        height: 6px;
        margin-left: 6px;
      }
    }
  }
}
.editorial-board-outer-module {
//   background: url("@/assets/img/lishi_bg.png") no-repeat;
  background-size: 100% 530px;
  background-position: center bottom;
  // margin-bottom:55px;
  margin-bottom: 0px;
}
.editorial-board-module {
  padding-bottom: 20px;

  .user-module {
    flex-wrap: wrap;
    align-items: stretch;


    &::after {
      content: "";
      width: 30%;
    }
  }
  .member-item {
    // display: flex;
    // width: 32%;
    // // height: 158px;
    // background: #ffffff;
    // padding: 24px 20px;
    // box-shadow: 0px 2px 4px 0px rgba(48, 0, 0, 0.25);
    // border-left: 4px solid #bd2b2f;
    // box-sizing: border-box;
    // margin-bottom: 30px;

    .name {
      font-size: 16px;
      text-align: left;
      font-weight: 600;
      margin-bottom: 6px;
      display:flex;
      align-items:center;
      color:#000;

      .job{
        font-size: 16px;
        font-weight:400;
        padding-right:4px;
      }
    }

    .editorial-name{
        margin-bottom:12px;
    }

    .job {
      font-size: 16px;
      text-align: left;
    }
    .desc {
      font-size: 16px;
      color: #707070;
      text-align: left;
      padding-top: 5px;
      display: -webkit-box;
    //   -webkit-box-orient: vertical;
    //   -webkit-line-clamp: 3;
    //   text-overflow: ellipsis;
    //   height: 60px;
    //   overflow: hidden;
      margin: 0px;
      line-height: 20px;
    }

    .avatar {
      width: 80px;
      height: 110px;
      margin-right: 24px;
    }

    .unit-logo{
        width: 50px; 
        height: 50px; 
        margin-right: 10px
    }
  }
}

.state-module {
  // margin-bottom:60px;
  margin-bottom: 0px;
  padding-bottom: 30px;

   .state-img-l{
        width:154px;
        height:207px;
        margin-left:14px;
    }

  .state-text {
    // background: #f6f6f6;
    width: 100%;
    // padding: 20px 25px 22px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing:border-box;

    

    p {
      text-align: left;
    }

    .state-title {
      color: #000;
      font-weight: 600;
      font-size:20px;
      padding-bottom: 15px;
      letter-spacing: 1px;
    }

    .state-sec-title{
        font-size: 16px;
        line-height: 24px;
        color: #666;
    }

    .state-con{
        align-items: stretch;
        margin-top:11px
    }

    .state-desc {
      font-size: 16px;
      overflow: hidden;
      letter-spacing: 1px;
      display:flex;
      flex-direction:column;
      justify-content:space-between;
    //   color:#BD2B2F;
      line-height:28px;
      font-weight:bold;
      color: #000;


      .basic-text{
        font-size: 16px;
        color: #333333;
        white-space: nowrap;
        font-weight:bold
      }
    }

    .state-date {
      color: #333333;
      font-size:14px;
      padding-top: 13px;
      font-weight:400;
    }
  }
}
.picg {
  // margin-bottom:60px;
  margin-bottom: 0px;
  padding-bottom: 40px;

  .picg-item {
    width: 20%;
    position: relative;
    box-shadow: 0px 2px 6px 0px rgba(48, 0, 0, 0.2);
  }

  .hidden-desc {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(188, 43, 47, 0.8);
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    padding: 10px 8px;
    box-sizing: border-box;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      p {
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #fff;
        border-radius: 50%;
        margin: 0 6px;
      }
    }
  }
}

.link-office-item{
    width: 32%;
    padding: 15px 20px;
    box-sizing:border-box;
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(48,0,0,0.25);
    
    .r-m-desc{
      font-size:12px;
      line-height:22px;
      padding-top:10px
    }
}

.link-office-item-s,.link-office-item{
    display:flex;
    .title{
      font-size:16px;
    //   font-weight: 600;
      color: #000;
      margin-bottom: 10px;
      padding-right:4px;
    }

    .address {
      font-size:12px;
      color: #3e3e3e;
      line-height: 14px;
      margin-bottom: 4px;
      padding:4px 0;
    }
      
    .avatar{
      width:80px;
      height:110px;
      margin-right:20px;
    }

    .name{
      font-size: 16px;
      font-weight:bold;
      line-height:20px;
      margin-bottom: 4px;
      padding:4px 0;
      color: #000;
    }
}
.link-module-outer {
//   background: url("@/assets/img/link_bg.png");
  background-size: 100% 100%;
//   padding: 20px 0 56px;
  box-sizing: border-box;

  

  .link-address {
    flex-wrap:wrap;
    margin-bottom:18px;
    // width:66%;

    &::after {
      content: "";
      width: 32%;
    }

    li {
    //   width: 31.9%;
    //   padding: 15px 20px;
    //   box-sizing:border-box;
    //   text-align: left;
    //   background: #FFFFFF;
    //   box-shadow: 0px 2px 4px 0px rgba(48,0,0,0.25);
    //   margin-bottom:24px;

    //   .name {
    //     font-size:20px;
    //     font-weight: 600;
    //     color: #402d2d;
    //     margin-bottom: 12px;
    //   }

    //   .address {
    //     font-size:12px;
    //     color: #3e3e3e;
    //     line-height: 14px;
    //     margin-bottom: 4px;
    //     padding:4px 0;
    //   }
      
    //   .avatar{
    //     width:80px;
    //     height:110px;
    //     margin-right:20px;
    //   }
    }
  }
}
.serve-module {
    align-items: stretch;
    margin-bottom:10px;

    flex-wrap: wrap;
    &::after {
        content: "";
        width: 32%;
    }
    li {
    //   width: 32%;
    //   text-align: left;
    //   padding: 16px 24px;
    //   margin-bottom: 24px;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-between;
    //   background: #F6F6F6;
    //   box-sizing: border-box;
    //   box-shadow: 0px 2px 4px 0px rgba(48,0,0,0.25);
    }

    //章程
    .finance-item{
        box-shadow: none;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(48, 0, 0, 0.25);
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      margin-bottom: 8px;
    }
    .desc {
      font-size: 16px;
      color: #000;
      margin-bottom: 21px;
    }
    .label {
      padding: 2px 6px;
      background: #DEDEDE;
      color: #666666;
      font-size: 12px;
      margin: 0 8px 4px 0;
      display: inline-block;
    }
  }

.link-b {
  background-color: #d50200;
  padding: 31px 0 27px;
  color: #ffffff;

  .link-num {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 12px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }

  .link-tip {
    font-size: 20px;
  }
}

.footer-info {
  background: #750100;
  color: #fff;
  .footer-title {
    font-size:16px;
    padding: 24px 0;
  }
  .footer-line-l{
    height: 2px;
    width: 42%
  }

  .footer-office {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size:16px;
    padding-top:16px;
  }

  li {
    padding-right: 20px;
    margin: 0 12px 12px 0;
    position: relative;

    .cute-line {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 50%;
      width: 1px;
      height: 14px;
      transform: translate(0, -50%);
      background: #fff;
    }
  }

  .footer-office-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size:14px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;
    padding-bottom: 13px;

    a{
        color: rgba(255,255,255,0.6);
        font-weight:400;
    }

    .cute-line {
      background: rgba(255, 255, 255, 0.6);
    }
  }
  .footer-blogroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size:14px;
    color: #FFF;
    margin-top: 20px;

    a{
        color: #FFF;
        font-weight:400;
    }

    .cute-line {
      background: #FFF
    }
  }
}
.min-height{
    min-height: 200px;
}
.user-desc{
  p{
    text-align:left;
    line-height:26px;
    color:#000;
    font-size:16px;
    text-indent: 2em;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:87;
}

.modal-con{
    width:800px;
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    z-index:88;
    background: #fff;

    .modal-title{
        background:#d50200;
        color:#fff;
        padding:14px;
        font-size:18px;

        .modal-close{
            cursor:pointer;

            .modal-img{
                width:28px;
                height:28px;
            }
        }
    }

    .user-desc{
        padding:30px;

        .user-name{
            font-size:20px;
            font-weight:bold;
            text-indent: 0em;
        }
    }
}

.modal-enter-active, .modal-leave-active {
  transition: opacity .5s;
}
.modal-enter, .modal-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.link-box{
    text-align: left;
    font-size:18px;
    color:#000;

    .address-box{
        padding-bottom: 8px;
        font-size:16px;
        color:#000;
        display:flex;
        align-items: center;
    }

    .phone-box{
        text-align: left;
        font-size:16px;
        color:#000;
        display:flex;
        align-items: center;
    }

    p{
        font-size:16px;
    }

    span{
        font-weight:bold;
    }
}
.governing-unit-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size:16px;
    padding-bottom:20px;
}

// 第二部分动态
.state-second-module{
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    margin-top:38px; 
    padding-top:44px;
    border-top: 1px solid #D3D3D3;

    .img-box{
        img{
            width:380px;
            height:206px;
            margin-right:32px
        }

        p{
            font-size: 14px;
            color: #666666;
            line-height: 22px;
            width:380px;
            text-align:left;
            margin-top:14px
        }
    }

    .state-title{
        color: #000;
        font-weight: 600;
        font-size:20px;
        padding-bottom: 15px;
        text-align:left;
    }

    .state-desc{
        line-height: 26px;
        text-indent: 2em;
        font-size: 16px;
        text-align:left;
        color:#3E3E3E;
    }

    .state-date{
        color:#333;
        font-size: 14px;
        margin-top:18px;
        text-align:left;
    }
}


//动态第三部分 第一部分
.state-third-module{
    margin-top:30px;
    padding-top:38px;
    border-top: 1px solid #D3D3D3;

    .float-left {
        float: right;
        width:580px;
        display:flex;
        margin-right: 15px; 

        img{
            width:184px;
            height:206px;
            margin-left:10px;
        }
    }

    p{
        text-align:left;
    }

    .state-title{
        text-align:left;
        font-size: 20px;
        font-weight:bold;
        margin-bottom:20px;
        color:#000;
    }

    .state-desc{
        font-size: 16px;
        text-align:left;
        text-indent: 2em;
        color:#3E3E3E;
        line-height: 28px;
    }

    .state-date{
        font-size: 14px;
        text-align:left;
        color:#333;
        line-height: 28px;
        margin-top:20px;
    }
}
.state-one-module{
    padding: 0 0 38px 0;
    border:none;
}
.state-old-one-module{
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    padding-top:30px;
    border-top: 1px solid #D3D3D3;
}
.state-third-module:after {
  content: "";
  display: table;
  clear: both;
}

</style>