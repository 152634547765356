<template>
  <div>
    <swiper
        :modules="modules"
        style="width: 500px"
        :loop="true"
        slidesPerView="auto"
        :spaceBetween="30"
        :autoplay="autoplay">
      <swiper-slide style="background: red;width: 80%">Slide 1</swiper-slide>
      <swiper-slide style="background: orange;width: 80%">Slide 2</swiper-slide>
      <swiper-slide style="width: 80%;background: blue">Slide 3</swiper-slide>
    </swiper>
    1
  </div>
</template>
<script setup>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Autoplay} from 'swiper/modules';

const modules = [Autoplay]
import {ref} from "vue";

const autoplay = ref({
  delay: 2500,
  disableOnInteraction: false
})
</script>
