import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomePage.vue'
import AboutView from '../views/AboutView.vue'


import NewView from '../views/NewView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView
    component: NewView

  },{
    path: '/asd',
    component: AboutView
  },{
    // path: '/new-view',
    // name: 'new-view',
    // component: NewView
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
